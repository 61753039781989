/*
 * @Author: your name
 * @Date: 2021-12-22 10:45:01
 * @LastEditTime: 2023-04-24 15:54:03
 * @LastEditors: OBKoro1
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEd
 * @FilePath: \iotmobile\src\services\GPTService.js
 */
import ApiService from './ApiService'
class GPTService extends ApiService {
    Chat(data) {
        return super.Post('/api/gpt/chat/', data);
    }
    // ChatStream(data) {
       
    //     return super.Post('/api/gpt/chatStream/', data);
    // }
}
export default new GPTService()